import React from 'react';
import {
  ListItem, ListItemButton, Box, ListItemText, IconButton, Link, Portal, Snackbar,
} from '@mui/material';
import { FileOpenOutlined, ContentCopyOutlined, ModeEditOutlineOutlined } from '@mui/icons-material';
import moment from 'moment';
import {
  capitalize, isEmpty, get,
} from 'lodash';
import { useQuery } from '@apollo/client';
import mixpanel from 'mixpanel-browser';
import CopyToClipboard from '../CopyToClipboard';
import { dashify, getExitDateTitle } from '../../utilities';
import { PetParent, PetParentAnimal, ProfileItemOption } from '../../types/PetParent';
import CustomContainer from './CustomContainer';
import ChangeNameDialog from './ChangeNameDialog';
import { GET_HUB_PET_PARENT } from '../../queries';

function SimpleListItem({
  primary,
  secondary,
  action,
  isEmptyMessage,
  onClick,
}: { primary: any; secondary: any; action: any; onClick?: () => void; isEmptyMessage?: string | boolean }) {
  return (
    <ListItem
      dense
      style={{
        padding: '2px 8px', minHeight: '48px', marginLeft: '-8px', marginRight: '-8px', width: 'auto',
      }}
      secondaryAction={action}
    >
      {
        onClick ? (
          <ListItemButton
            onClick={onClick}
            sx={{
              width: '100%', mx: -2, minHeight: '48px',
            }}
          >
            <ListItemText
              primaryTypographyProps={{ variant: 'body2', sx: { color: isEmptyMessage || !(primary && secondary) ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.6)' } }}
              secondaryTypographyProps={{
                variant: 'body2',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                sx: { color: isEmptyMessage ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0, 1)' },
              }}
              {...{ primary, secondary: isEmptyMessage || secondary }}
            />
          </ListItemButton>
        ) : (
          <Box sx={{ width: '100%' }}>
            <ListItemText
              primaryTypographyProps={{ variant: 'body2', sx: { color: isEmptyMessage || !(primary && secondary) ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.6)' } }}
              secondaryTypographyProps={{
                variant: 'body2',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                sx: { color: isEmptyMessage ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0, 1)' },
              }}
              {...{ primary, secondary: isEmptyMessage || secondary }}
            />
          </Box>
        )

      }
    </ListItem>
  );
}

function MyPets() {
  const [animalChangeName, setAnimalChangeName] = React.useState<PetParentAnimal | undefined>();
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const copyText = async (text: string, message: string) => {
    await navigator.clipboard.writeText(text);
    setSnackbarMessage(message);
  };

  const { data, loading } = useQuery<{ petParent: PetParent }>(GET_HUB_PET_PARENT);
  // test
  if (!data || loading) return null;
  const defaultValues = data.petParent ? {
    id: data.petParent.id,
    firstName: capitalize(data.petParent.firstName),
    lastName: capitalize(data.petParent.lastName),
    email: data.petParent.email.toLowerCase(),
    phoneNumber: data.petParent.phoneNumber,
    streetAddress: data.petParent.streetAddress,
    state: data.petParent.state,
    country: data.petParent.country,
    awoName: data.petParent.awo.name,
  } : {};

  const emptyMessage = `Contact ${data.petParent.awo.name} for more info`;
  const { awo } = data.petParent;
  const allAnimals = data.petParent.animals
    .filter((animal) => ['Adopted', 'Co-Sheltering'].includes(animal.careType))
    .map((animal) => {
      const pdfMonkeyUrl = get(animal.animalMedias.find((am) => am.fileType === 'pdf' && am.url.includes('pdfmonkey')), 'pdfMonkeyUrl.url');
      const awsUrl = get(animal.animalMedias.find((am) => am.fileType === 'pdf' && am.url.includes('shelterbuddy-records')), 'awsUrl.url');
      const medicalRecordUrl = pdfMonkeyUrl || awsUrl;

      return {
        title: `${animal.name} ${animal.animalType.toLowerCase() === 'dog' ? '🐶' : '🐱'}`,
        items: [{
          subheader: 'Change pet name',
          action: (
            <IconButton
              onClick={() => {
                mixpanel.track('pet_name_edit_click', {
                  pet_name: animal.name,
                }, () => setAnimalChangeName(animal));
              }}
              sx={{ marginRight: '-4px' }}
            >
              <ModeEditOutlineOutlined fontSize="small" />
            </IconButton>
          ),
          onClick: () => {
            mixpanel.track('pet_name_edit_click', {
              pet_name: animal.name,
            }, () => setAnimalChangeName(animal));
          },
        }, {
          subheader: 'Frequently asked questions',
          action: (
            <Link
              href={awo.faqLink}
              underline="hover"
              rel="noopener"
              target="_blank"
            >
              <IconButton sx={{ marginRight: '-4px' }}>
                <FileOpenOutlined fontSize="small" />
              </IconButton>

            </Link>
          ),
          onClick: () => {
            window.open(awo.faqLink, '_blank', 'noopener');
          },
        }, {
          subheader: 'Medical records',
          action: medicalRecordUrl && (
            <IconButton
              sx={{ marginRight: '-4px' }}
              onClick={() => {
                mixpanel.track('medical_record_view_click', undefined, () => {
                  window.open(medicalRecordUrl, '_blank')?.focus();
                });
              }}
            >
              <FileOpenOutlined fontSize="small" />
            </IconButton>
          ),
          onClick: medicalRecordUrl ? () => {
            mixpanel.track('medical_record_view_click', undefined, () => {
              window.open(medicalRecordUrl, '_blank')?.focus();
            });
          } : undefined,
          isEmptyMessage: !medicalRecordUrl && emptyMessage,
        }, {
          subheader: 'Adoption packet',
          action: (animal.animalType === 'Dog' ? awo.adoptionPacketLinkDogs : awo.adoptionPacketLinkCats)
            && (
              <IconButton
                sx={{ marginRight: '-4px' }}
                onClick={() => {
                  mixpanel.track('adoption_packet_view_click', undefined, () => {
                    window.open(animal.animalType === 'Dog' ? awo.adoptionPacketLinkDogs : awo.adoptionPacketLinkCats, '_blank', 'noopener');
                  });
                }}
              >
                <FileOpenOutlined fontSize="small" />
              </IconButton>
            ),
          onClick: (animal.animalType === 'Dog' ? awo.adoptionPacketLinkDogs : awo.adoptionPacketLinkCats) ? () => {
            mixpanel.track('adoption_packet_view_click', undefined, () => {
              window.open(animal.animalType === 'Dog' ? awo.adoptionPacketLinkDogs : awo.adoptionPacketLinkCats, '_blank', 'noopener');
            });
          } : undefined,
          isEmptyMessage: !(awo.adoptionPacketLinkDogs || awo.adoptionPacketLinkCats) && emptyMessage,
        }, {
          subheader: 'Species',
          content: dashify(capitalize(animal.animalType)),
        }, {
          subheader: 'Sex',
          content: dashify(animal.gender),
          isEmptyMessage: !animal.gender && emptyMessage,
        }, {
          subheader: 'Primary breed',
          content: dashify(capitalize(animal.primaryBreed)),
          isEmptyMessage: !animal.primaryBreed && emptyMessage,
        }, {
          subheader: 'Date of birth',
          content: dashify(moment(animal.birthDate).format('M/DD/YYYY')),
          isEmptyMessage: !animal.birthDate && emptyMessage,
        }, {
          subheader: `${getExitDateTitle(animal.careType)}`,
          content: dashify(moment(animal.exitDate).format('M/DD/YYYY')),
          isEmptyMessage: !animal.exitDate && emptyMessage,
        }, {
          subheader: 'Microchip',
          content: dashify(animal.microchipNumber),
          isEmptyMessage: !animal.microchipNumber && emptyMessage,
          action: animal.microchipNumber && (
            <IconButton
              sx={{ marginRight: '-4px' }}
              onClick={() => {
                mixpanel.track('microchip_number_copied', undefined, () => {
                  copyText(animal.microchipNumber || '', 'Microchip copied');
                });
              }}
            >
              <ContentCopyOutlined fontSize="small" />
            </IconButton>
          ),
          onClick: animal.microchipNumber ? () => {
            mixpanel.track('microchip_number_copied', undefined, () => {
              copyText(animal.microchipNumber || '', 'Microchip copied');
            });
          } : undefined,
        }],
      } as ProfileItemOption;
    });

  return (
    <CustomContainer sx={{ gap: 3, pt: 3 }}>
      {
        allAnimals.map((animal, idx) => (
          <Box key={idx} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <ListItemText
              style={{ padding: '2px 8px', marginLeft: '-8px', marginRight: '-8px' }}
              primary={`${!isEmpty(animal.title) ? capitalize(animal.title) : 'No  name'}`}
              primaryTypographyProps={{
                variant: 'sectionTitle',
              }}
            />
            {animal.items.map((item, itemIdx) => (
              <SimpleListItem
                key={itemIdx}
                primary={item.subheader}
                secondary={item.content}
                action={item.action}
                isEmptyMessage={item.isEmptyMessage}
                onClick={item.onClick}
              />
            ))}
          </Box>
        ))
      }
      <ChangeNameDialog
        petParent={defaultValues}
        animal={animalChangeName}
        onClose={() => { setAnimalChangeName(undefined); }}
      />
      <Portal container={() => document.getElementById('root')}>
        <Snackbar
          open={Boolean(snackbarMessage)}
          autoHideDuration={6000}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          message={snackbarMessage || 'Copied to clipboard successfully'}
          onClose={() => {
            setSnackbarMessage('');
          }}
        />
      </Portal>
    </CustomContainer>
  );
}

export default MyPets;
