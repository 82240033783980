import React from 'react';
import { Box } from '@mui/material';
import { isNil } from 'lodash';
import { cardShadow } from '../../theme';

interface Props {
  sendDelay?: number;
  text: string;
  error?: boolean;
  active?: boolean;
  disableHover?: boolean;
}

function MessageTemplate({
  text, sendDelay, error, active, disableHover = false,
}: Props) {
  return (
    <Box
      className={
        active ? 'active' : ''
      }
      sx={{
        borderRadius: '4px',
        transition: 'all 0.2s ease-in-out',
        '& .content': {
          transition: 'all 0.2s ease-in-out',
        },
        '&:hover': {
          backgroundColor: disableHover ? '#FFF' : 'grey.50',
        },
        '&.active': {
          backgroundColor: 'grey.300',
          '& .content': {
            backgroundColor: 'grey.50',
          },
        },
        ...cardShadow,
      }}
    >
      <Box sx={{
        padding: 2,
        color: !error ? 'primary.main' : 'error.light',
      }}
      >
        {isNil(sendDelay) ? 'New message' : `Day ${sendDelay}`}
      </Box>
      <Box sx={{
        paddingLeft: 2,
        paddingRight: 2,
        paddingBottom: 2,
      }}
      >
        <Box
          className="content"
          sx={{
            borderRadius: '8px',
            padding: 2,
            backgroundColor: 'grey.100',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'break-spaces',
          }}
        >
          {text.length > 0
            ? text.replaceAll('{f_name}', 'Bailey')
              .replaceAll('{a_name}', 'Bodie')
              .replaceAll('{care_type}', 'Foster')
              .replaceAll('{email}', 'bailey@petcademy.org')
              .replaceAll('{awo_name}', 'Philz Pets')
              .replaceAll('{awo_name_encoded}', 'Philz+Pets')
              .replaceAll('{awo_number}', '(555) 555-5555')
              .replaceAll('{end_date}', '11/23/29')
              .replaceAll('{onboard_date}', '10/23/29')
              .replaceAll('{sign_in_link}', 'https://learn.petcademy.org/users/express_signin?email=bailey@petcademy.org')
              .replaceAll('{hub_welcome}', 'https://app.petcademy.org/hub/welcome')
              .replaceAll('{hub_home}', 'https://app.petcademy.org/hub/home')
              .replaceAll('{hub_my_pets}', 'https://app.petcademy.org/hub/my-pets')
              .replaceAll('{hub_ask}', 'https://app.petcademy.org/hub/ask')
              .replaceAll('{hub_dog_day_10_survey}', 'https://app.petcademy.org/hub/dog-adopter-day-10-checkin')
              .replaceAll('{hub_cat_day_10_survey}', 'https://app.petcademy.org/hub/cat-adopter-day-10-checkin')
              .replaceAll('{hub_adoption_feedback_survey}', 'https://app.petcademy.org/hub/adoption-review')
              .replaceAll('{hub_petcademy_experience_survey}', 'https://app.petcademy.org/hub/petcademy-review')
              .replaceAll('{awo_donation}', 'https://www.goodshepherd-hs.org/donations')
            : <Box sx={{ color: 'disabled.textContrastLight' }}>No message for this day.</Box>}
        </Box>
      </Box>
    </Box>
  );
}

export default MessageTemplate;
