import React from 'react';
import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import mixpanel from 'mixpanel-browser';
import { useMutation } from '@apollo/client';
import { grey } from '@mui/material/colors';
import { AutoAwesomeOutlined, CheckCircleOutline } from '@mui/icons-material';
import { SEND_MAGIC_LINK_EMAIL } from '../../queries';
import { colors } from '../../theme';

function LoginContainer() {
  mixpanel.track('magic_link_page_view');
  const [email, setEmail] = React.useState('');
  const [buttonClicked, setButtonClicked] = React.useState(false);
  const [sendMagicLinkEmail] = useMutation(SEND_MAGIC_LINK_EMAIL);
  const beginningContent = (
    <Box
      sx={{
        background: 'white',
        px: 3,
        py: 4,
        borderRadius: '6px',
      }}
    >
      <TextField
        autoFocus
        id="email"
        label="Email"
        type="email"
        fullWidth
        variant="outlined"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Box>
        <Button
          sx={{
            mt: 2,
            mb: 4,
            width: '100%',
          }}
          type="submit"
          variant="contained"
          onClick={() => {
            sendMagicLinkEmail({
              variables: {
                email,
              },
            });
            mixpanel.track('magic_link_click');
            setButtonClicked(true);
          }}
        >
          Send magic link
        </Button>
      </Box>
      <Box
        sx={{
          bgcolor: colors.primary[50],
          py: 1,
          px: 2,
        }}
      >
        <Box
          sx={{
            pr: 1.5,
            display: 'inline',
            color: 'primary.dark',
          }}
        >
          <AutoAwesomeOutlined />
        </Box>
        <Typography variant="body" color="primary.dark" fontWeight="500">We&apos;ll email you a magic link to sign in without a password</Typography>
      </Box>
    </Box>
  );

  const submitContent = (
    <Box
      sx={{
        background: 'white',
        p: 3,
        borderRadius: '6px',
        textAlign: 'center',
        color: 'primary.dark',
      }}
    >
      <CheckCircleOutline
        sx={{
          width: '40px',
          height: '40px',
          pb: 1,
        }}
      />
      <Typography variant="h6" sx={{ pb: 3 }}>Magic link sent to your email</Typography>
      <Typography variant="body2" sx={{ pb: 2 }}>If there is an account associated with this email address, you should receive a magic link shortly to sign in to your account.</Typography>
      <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">Contact support@petcademy.org if you have trouble signing in</Typography>
    </Box>
  );

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: grey[100],
      }}
    >
      <Box
        sx={{
          pt: 5,
          pb: 2,
          px: 2,
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 600,
          mx: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            py: 2,
            mx: -1,
          }}
        >
          <IconButton
            sx={{
              borderRadius: '6px',
              m: 0,
            }}
            edge="start"
            color="inherit"
          >
            <img style={{}} alt="Petcademy Logo" src="/logo_hub.svg" />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', pb: 5 }}>
          <Typography variant="h4" color="primary.dark">Sign in to your account</Typography>
        </Box>
        {buttonClicked ? submitContent : beginningContent}
      </Box>
    </Box>
  );
}

export default LoginContainer;
