import React from 'react';
import { NetworkStatus, useMutation } from '@apollo/client';
import {
  Box,
  Button, CardActions, CardContent, Checkbox,
  Chip, Dialog, DialogTitle, FormControlLabel, FormGroup,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography,
} from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import dayjs from 'dayjs';
import { isNil } from 'lodash';

import { LoadingButton } from '@mui/lab';
import { AwoUser, AwoUserMutationResponse } from '../../types/Awo';
import { MembersTableFields } from '../../types/Tables';
import MembersDialog from './MembersDialog';
import MembersRemoveDialog from './MembersRemoveDialog';
import { REINVITE_AWO_USER, UPDATE_AWO_USER } from '../../queries';
import { useAwoContext } from '../AwoContextProvider';
import { getMostRecentDate } from '../../utilities';

interface MembersTableProps {
  orderBy: string;
  order: 'desc' | 'asc';
  handleChangeOrder: (event: any, field: MembersTableFields) => void;
  loading: boolean;
  members: AwoUser[];
  networkStatus: NetworkStatus;
  refetch: () => void;
}

function MembersTable({
  orderBy,
  order,
  handleChangeOrder, loading, members, networkStatus, refetch,
}: MembersTableProps) {
  const headCells = [{
    id: 'first_name',
    label: 'Name',
    width: '25%',
  }, {
    id: 'email',
    label: 'Email',
    width: '25%',
  }, {
    id: 'taskNotificationsEmail',
    label: 'Task notifications via email',
    width: '20%',
    disableSorting: true,
  }, {
    id: 'last_login',
    label: 'Last active',
    width: '25%',
  }, {
    id: 'button',
    label: '',
    width: '5%',
    disableSorting: true,
  }];

  const { awo } = useAwoContext();
  const isCoSheltering = awo?.coSheltering;
  const isCoShelteringCoordinators = awo?.coShelteringCoordinators;

  const createSortHandler = (property: any) => (event: any) => {
    handleChangeOrder(event, property);
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [currentRow, setCurrentRow] = React.useState<AwoUser | undefined>(undefined);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [dialog, setDialog] = React.useState(false);
  const [removeDialog, setRemoveDialog] = React.useState(false);
  const [notificationDialog, setNotificationDialog] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, row: AwoUser) => {
    setCurrentRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handleCellClick = (event: React.MouseEvent<HTMLTableCellElement>, row: AwoUser) => {
    setCurrentRow(row);
    setDialog(true);
  };

  const handleNotificationClick = (row: AwoUser) => {
    setCurrentRow(row);
    setNotificationDialog(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopyEmailAddress = (email: string) => {
    navigator.clipboard.writeText(email);
    setSnackbarMessage('Email address copied');
    setSnackbarOpen(true);
    setAnchorEl(null);
  };

  const onCompleted = (data: { response: AwoUserMutationResponse }) => {
    const { response } = data;
    if (response.message) {
      setSnackbarOpen(true);
      setSnackbarMessage(response.message);
      setDialog(false);
      setRemoveDialog(false);
      setNotificationDialog(false);
    }
  };

  const [updateTaskEmailSetting, { loading: updateLoading }] = useMutation(UPDATE_AWO_USER, { onCompleted });
  const [reinviteUser] = useMutation(REINVITE_AWO_USER, { onCompleted });

  const getNotificationLabel = (awoUser: AwoUser) => {
    if (isCoSheltering) {
      if (awoUser.taskNotificationsEmail && awoUser.ownClientNotificationEmail) {
        return 'All';
      }
      if (awoUser.taskNotificationsEmail || awoUser.ownClientNotificationEmail) {
        return 'Selected';
      }
    } else {
      if (awoUser.adoptionNotificationEmail
        && awoUser.fosterNotificationEmail
        && awoUser.preSurrenderNotificationEmail) {
        return 'All';
      }
      if (awoUser.adoptionNotificationEmail
        || awoUser.fosterNotificationEmail
        || awoUser.preSurrenderNotificationEmail) {
        return 'Selected';
      }
    }
    return 'None';
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: '500px' }} aria-label="Members Table" size="small">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                  sx={{
                    width: headCell.width,
                  }}
                >
                  {headCell.disableSorting ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading || networkStatus === NetworkStatus.refetch ? (
              <tr>
                <td aria-label="Loading progress" colSpan={10}>
                  <Box sx={{ height: '4px', transform: 'translateY(-1px)' }}>
                    <LinearProgress />
                  </Box>
                </td>
              </tr>
            ) : null}
            {!loading && members.map((row) => (
              <TableRow
                key={`${row.id}_${row.firstName}`}
                hover
                sx={{ cursor: 'pointer' }}
              >
                <TableCell
                  sx={{
                    maxWidth: 0,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                  onClick={(e) => handleCellClick(e, row)}
                >
                  {[row.firstName, row.lastName].filter((item) => item).join(' ')}
                </TableCell>
                <TableCell
                  sx={{
                    maxWidth: 0,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                  onClick={(e) => handleCellClick(e, row)}
                >
                  {row.email}
                </TableCell>
                <TableCell>
                  {(isCoSheltering && !isCoShelteringCoordinators) ? (
                    <Switch
                      size="small"
                      checked={row.taskNotificationsEmail}
                      onChange={() => {
                        updateTaskEmailSetting({
                          variables: {
                            id: row.id,
                            awoUser: {
                              email: row.email,
                              taskNotificationsEmail: !row.taskNotificationsEmail,
                            },
                          },
                        });
                      }}
                    />
                  ) : (
                    <Button
                      onClick={() => handleNotificationClick(row)}
                      sx={{ minWidth: 'auto' }}
                      size="small"
                    >
                      {getNotificationLabel(row)}
                    </Button>
                  )}
                </TableCell>
                <TableCell
                  onClick={(e) => handleCellClick(e, row)}
                >
                  <Chip
                    label={isNil(row.lastLogin) && isNil(row.lastTaskAction)
                      ? `Invite sent ${dayjs(row.inviteDate).format('M/D/YY')}`
                      : getMostRecentDate(row.lastLogin, row.lastTaskAction, 'M/D/YY')}
                  />
                </TableCell>
                <TableCell>
                  <IconButton onClick={(e) => handleClick(e, row)}>
                    <MoreHoriz />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {dialog ? (
        <MembersDialog
          title="Edit team member"
          subtitle="Edit information for this user"
          buttonText="Save changes"
          setDialog={setDialog}
          member={currentRow}
          awoId={currentRow!.awoId}
          onSuccess={(message: string) => {
            setSnackbarOpen(true);
            setSnackbarMessage(message);
            setDialog(false);
            refetch();
          }}
        />
      ) : null}
      {removeDialog ? (
        <MembersRemoveDialog
          setDialog={setRemoveDialog}
          member={currentRow}
          awoName={awo?.name || ''}
          onSuccess={(message: string) => {
            setSnackbarOpen(true);
            setSnackbarMessage(message);
            setRemoveDialog(false);
            refetch();
          }}
        />
      ) : null}
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          elevation: 2,
        }}
      >
        <MenuItem
          onClick={() => {
            reinviteUser({
              variables: {
                id: currentRow!.id,
                awoUser: {
                  email: currentRow!.email,
                },
              },
            });
            setAnchorEl(null);
          }}
        >
          Resend invite
        </MenuItem>
        <MenuItem onClick={() => handleCopyEmailAddress(currentRow!.email)}>Copy email address</MenuItem>
        <MenuItem
          sx={{ color: 'error.light' }}
          onClick={() => {
            setAnchorEl(null);
            setRemoveDialog(true);
          }}
        >
          Remove Member
        </MenuItem>
      </Menu>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        message={snackbarMessage}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      />
      <Dialog
        open={notificationDialog}
        onClose={() => setNotificationDialog(false)}
        aria-labelledby="filter-dialog-title"
        aria-describedby="filter-dialog-description"
        maxWidth={false}
        PaperProps={{
          sx: {
            width: '600px',
            maxWidth: '90vw',
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h5">Task notifications via email</Typography>
          <Typography variant="body2" color="text.secondary">{`If there are outstanding tasks to review, an email will be sent to ${currentRow?.firstName}. Deselect all to stop task notifications via email.`}</Typography>
        </DialogTitle>
        <CardContent sx={{ paddingTop: 0 }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
            <FormGroup sx={{ flexGrow: 1 }}>
              {
                isCoSheltering && (
                <>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={currentRow?.ownClientNotificationEmail}
                        // @ts-ignore
                        onChange={(e) => setCurrentRow({
                          ...currentRow,
                          ownClientNotificationEmail: e.target.checked,
                        })}
                      />
                    )}
                    label="My clients"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={currentRow?.taskNotificationsEmail}
                        // @ts-ignore
                        onChange={(e) => setCurrentRow({
                          ...currentRow,
                          taskNotificationsEmail: e.target.checked,
                        })}
                      />
                    )}
                    label="All clients"
                  />
                </>
                )
              }
              {
                !isCoSheltering && (
                <>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={currentRow?.adoptionNotificationEmail}
                    // @ts-ignore
                        onChange={(e) => setCurrentRow({
                      ...currentRow,
                      adoptionNotificationEmail: e.target.checked,
                    })}
                      />
                )}
                    label="Adopted pets"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={currentRow?.fosterNotificationEmail}
                    // @ts-ignore
                        onChange={(e) => setCurrentRow({
                      ...currentRow,
                      fosterNotificationEmail: e.target.checked,
                    })}
                      />
                )}
                    label="Fostered pets"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={currentRow?.preSurrenderNotificationEmail}
                    // @ts-ignore
                        onChange={(e) => setCurrentRow({
                      ...currentRow,
                      preSurrenderNotificationEmail: e.target.checked,
                    })}
                      />
                )}
                    label="Pets at risk of surrender"
                  />
                </>
                )
              }
            </FormGroup>
          </Box>
        </CardContent>
        <CardActions>
          <LoadingButton
            variant="contained"
            loading={updateLoading}
            onClick={() => updateTaskEmailSetting({
              variables: {
                id: currentRow?.id,
                awoUser: {
                  email: currentRow?.email,
                  adoptionNotificationEmail: currentRow?.adoptionNotificationEmail,
                  fosterNotificationEmail: currentRow?.fosterNotificationEmail,
                  preSurrenderNotificationEmail: currentRow?.preSurrenderNotificationEmail,
                  ownClientNotificationEmail: currentRow?.ownClientNotificationEmail,
                  taskNotificationsEmail: currentRow?.taskNotificationsEmail,
                },
              },
            })}
          >
            Save changes
          </LoadingButton>
          <Button onClick={() => setNotificationDialog(false)}>Cancel</Button>
        </CardActions>
      </Dialog>
    </>
  );
}

export default MembersTable;
