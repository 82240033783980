import React from 'react';
import { NetworkStatus } from '@apollo/client';
import {
  Box, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow,
} from '@mui/material';
import { capitalize } from 'lodash';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { hashids, PETCADEMY_SHADOW, phoneFormatter } from '../utilities';
import EnhancedTableHeader from './EnhancedTableHeader';
import TablePaginationActions from './TablePaginationActions';

function CasesTable({
  rowsPerPage, rowsPerPageOptions, page, count, orderBy, order, handleChangeRowsPerPage, handleChangePage,
  handleChangeOrder, loading, petParents, networkStatus,
}) {
  const navigate = useNavigate();
  const headCells = [{
    id: 'first_name',
    width: '10%',
    label: 'First Name',
  }, {
    id: 'last_name',
    width: '10%',
    label: 'Last Name',
  }, {
    id: 'email',
    width: '15%',
    label: 'Email',
  }, {
    id: 'sms_sync_date',
    width: '10%',
    label: 'Date Invited',
  }, {
    id: 'phone_number',
    width: '15%',
    label: 'Phone',
  }, {
    id: 'sms_sync_date_status',
    width: '10%',
    label: 'SMS Status',
    disableSorting: true,
  }, {
    id: 'case_number',
    width: '10%',
    label: 'Case Number',
  }, {
    id: 'animal_type',
    width: '10%',
    label: 'Species',
  }, {
    id: 'animal.name',
    width: '10%',
    label: 'Animal Name',
  }];

  const getSMSStatus = (row) => {
    if (row.smsSyncDate && row.smsOptOutDate) {
      return 'Opted Out';
    } if (row.smsSyncDate && !row.smsOptOutDate) {
      return 'Opted In';
    }
    return 'N/A';
  };

  return (
    <TableContainer component={Paper} elevation={PETCADEMY_SHADOW}>
      <Table sx={{ minWidth: '500px' }} aria-label="Courses Table" size="small">
        <EnhancedTableHeader
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          handleChangeOrder={handleChangeOrder}
        />
        {loading || networkStatus === NetworkStatus.refetch ? (
          <tr>
            <td aria-label="Loading progress" colSpan={10}>
              <Box sx={{ height: '4px', transform: 'translateY(-1px)' }}>
                <LinearProgress />
              </Box>
            </td>
          </tr>
        ) : null}
        <TableBody>
          {!loading && petParents.map((row) => (
            <TableRow
              key={`${row.id}-${row.animalType}-${row.careType}`}
              hover
              onClick={() => {
                navigate(`/cases#id=${hashids.encode(row.id)}`);
              }}
            >
              <TableCell sx={{
                maxWidth: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              >
                {capitalize(row.firstName)}
              </TableCell>
              <TableCell sx={{
                maxWidth: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              >
                {capitalize(row.lastName)}
              </TableCell>
              <TableCell sx={{
                maxWidth: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              >
                {row.email.toLowerCase()}
              </TableCell>
              <TableCell>{row.smsSyncDate ? moment(row.smsSyncDate).format('MM/DD/YYYY') : 'N/A'}</TableCell>
              <TableCell>{phoneFormatter(row.phoneNumber)}</TableCell>
              <TableCell>{getSMSStatus(row)}</TableCell>
              <TableCell>{row.caseNumber || '-'}</TableCell>
              <TableCell>
                {row.animalType.toLowerCase() === 'dog' && 'Dog 🐶'}
                {row.animalType.toLowerCase() === 'cat' && 'Cat 🐱'}
                {row.animalType.toLowerCase() === 'kitten' && 'Kitten 🐱'}
              </TableCell>
              <TableCell>{row.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              colSpan={8}
              rowsPerPageOptions={rowsPerPageOptions}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

export default CasesTable;
