import React from 'react';
import { Widget } from '@typeform/embed-react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import { GET_TYPEFORM_PET_PARENT } from '../../queries';

const typeformWidgets: Record<string, string> = {
  '/hub/dog-adopter-day-10-checkin': 'tdyG9TOu',
  '/hub/cat-adopter-day-10-checkin': 'yEcGoS7z',
  '/hub/adoption-review': 'APrSPnwQ',
  '/hub/petcademy-review': 'GW6sjaND',
};

function Typeform() {
  const location = useLocation();
  const widgetTypeformId = typeformWidgets[location.pathname];

  const { loading, data } = useQuery(GET_TYPEFORM_PET_PARENT);

  if (!widgetTypeformId) {
    return null;
  }

  if (loading) return null;

  const animalName = get(data, 'petParent.animals[0].name', 'your pet');

  return (
    <Widget
      inlineOnMobile
      fullScreen
      id={widgetTypeformId}
      style={{
        height: '100vh',
        width: '100%',
        margin: '0',
        overflow: 'hidden',
      }}
      hidden={{
        awo: data.petParent.awo.name,
        email: data.petParent.email,
        name: animalName,
      }}
    />
  );
}

export default Typeform;
