import {
  Box,
  Collapse, IconButton,
  Link,
  List,
  ListItemButton,
  ListItemText, ListSubheader,
  Typography,
} from '@mui/material';
import {
  capitalize, get, isEmpty,
} from 'lodash';
import {
  ContentCopyOutlined, ExpandLess, ExpandMore,
} from '@mui/icons-material';
import moment from 'moment';
import React, { useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
  dashify, getCareType, getExitDateTitle, isInactiveAnimal,
  phoneFormatter, getMembershipDaysLeft,
} from '../../utilities';
import TypeformResponse from '../UpdateCards';
import MediaThumbnail from './MediaThumbnail';
import CopyToClipboard from '../CopyToClipboard';
import ListItemEditable from './ListItemEditable';
import EditingModeContext from './editingContext';
import MediaDialog from '../MediaDialog';
import { PetParent, PetParentAnimal, ProfileItemOption } from '../../types/PetParent';
import { useAwoContext } from '../AwoContextProvider';
import MessageList from './MessageList';

const PREMIUM_BUNDLE_IDS = [3, 7, 8];

export default function ProfileContent({
  isAdmin, showInactiveAnimals, petParent, refetch,
}: { isAdmin: boolean, showInactiveAnimals: boolean, petParent: PetParent, refetch: unknown }) {
  const { awo } = useAwoContext();
  const isCoSheltering = awo?.coSheltering;
  const [expandables, setExpandables] = React.useState(() => new Set(['Updates']));
  const [editableFieldParent, setEditableFieldParent] = React.useState<boolean | string>(false);
  const [dialogImg, setDialogImg] = React.useState('');
  const [dialogDate, setDialogDate] = React.useState('');
  const [imgDialogOpen, setImgDialogOpen] = React.useState(false);
  const editingProviderDefault = useMemo(() => ({
    editableFieldParentLabel: editableFieldParent,
    setEditableFieldParentLabel: setEditableFieldParent,
    petParent,
    refetch,
  }), [petParent, refetch, editableFieldParent]);

  const toggleExpandables = (item: string) => {
    if (expandables.has(item)) {
      setExpandables((prev) => {
        const next = new Set(prev);
        if (editableFieldParent === item) {
          setEditableFieldParent(false);
        }
        next.delete(item);
        return next;
      });
    } else {
      setExpandables((prevState) => new Set(prevState).add(item));
    }
  };

  const groupedAnimals = useMemo(() => {
    const active: PetParentAnimal[] = [];
    const inactive: PetParentAnimal[] = [];
    petParent.animals.forEach((a) => {
      if (isInactiveAnimal(a)) {
        inactive.push(a);
      } else {
        active.push(a);
      }
    });
    return { active, inactive: inactive.sort((a, b) => dayjs(a.exitDate).diff(dayjs(b.exitDate))).reverse() };
  }, [petParent.animals]);

  const orders = get(petParent, 'orders', []);
  const premiumOrder = orders.find((o) => PREMIUM_BUNDLE_IDS.includes(o.productId));
  let membershipString = get(petParent, 'awo.membershipType');
  if (premiumOrder) {
    membershipString = premiumOrder.product.name;
  }
  if (get(petParent, 'awo.name', '') === '24Pet LPM') {
    membershipString = `LPM+ ${membershipString}`;
  }
  const membershipDaysLeft = getMembershipDaysLeft(petParent.startedDaysAgo, membershipString);
  const membershipStatus = membershipDaysLeft >= 0 ? 'Active' : 'Inactive';

  const address = dashify([petParent.state, petParent.country].filter((e) => e !== null && e !== '').join(', '));

  const animalCare = (animal: PetParentAnimal) => {
    if (isCoSheltering) {
      return 'Co-Sheltering';
    }
    return animal.careType && animal.awo.name !== null ? `${getCareType(animal.careType)}` : null;
  };

  const animalContent = (animal: PetParentAnimal, idx: number, inactive?: boolean) => {
    const animalOption: ProfileItemOption = {
      visible: true,
      title: `${animal.name}_${idx}`,
      items: [{
        visible: true,
        subheader: 'Name',
        content: !isEmpty(animal.name) ? capitalize(animal.name) : 'No  name',
        editable: {
          parentLabel: `${animal.name}_${idx}`,
          label: 'Name',
          name: `animals[${idx}].name`,
          validation: { validate: (val: unknown) => val !== null || 'Cannot be blank' },
        },
      }, {
        visible: true,
        subheader: 'Animal Welfare Organization',
        content: dashify(animal.awo.name),
      }, {
        visible: true,
        subheader: 'Relationship',
        content: `${(dashify(animalCare(animal)))}`,
        editable: !isCoSheltering && {
          parentLabel: `${animal.name}_${idx}`,
          label: 'Relationship',
          name: `animals[${idx}].careType`,
          options: [
            {
              id: 'Adopted',
              label: 'Adopted',
            },
            {
              id: 'Foster',
              label: 'Fostered',
            },
            {
              id: 'Breeder',
              label: 'Breeder',
            },
            {
              id: 'Pre-Surrender',
              label: 'Pre-Surrender',
            },
            {
              id: 'Other',
              label: 'Other',
            },
            {
              id: '',
              label: '-',
            },
          ],
          type: 'select',
        },
      }, {
        visible: true,
        subheader: 'Species',
        content: dashify(capitalize(animal.animalType)),
        editable: {
          parentLabel: `${animal.name}_${idx}`,
          label: 'Species',
          name: `animals[${idx}].animalType`,
          options: [
            {
              id: 'Dog',
              label: 'Dog',
            },
            {
              id: 'Cat',
              label: 'Cat',
            },
          ],
          type: 'select',
        },
      }, {
        visible: !isCoSheltering,
        subheader: 'Sex',
        content: dashify(animal.gender),
        editable: {
          parentLabel: `${animal.name}_${idx}`,
          label: 'Sex',
          name: `animals[${idx}].gender`,
          options: [
            {
              id: 'Male',
              label: 'Male',
            },
            {
              id: 'Female',
              label: 'Female',
            },
            {
              id: 'Unknown',
              label: 'Unknown',
            },
          ],
          type: 'select',
        },
      }, {
        visible: !isCoSheltering,
        subheader: 'Primary Breed',
        content: dashify(capitalize(animal.primaryBreed)),
        editable: {
          parentLabel: `${animal.name}_${idx}`,
          label: 'Primary Breed',
          name: `animals[${idx}].primaryBreed`,
          validation: { validate: (val: unknown) => val !== null || 'Cannot be blank' },
        },
      }, {
        visible: !isCoSheltering,
        subheader: 'Date of birth',
        content: dashify(moment(animal.birthDate).format('M/DD/YYYY')),
        editable: {
          parentLabel: `${animal.name}_${idx}`,
          label: 'Date of birth',
          name: `animals[${idx}].birthDate`,
          validation: { validate: (val: unknown) => val !== null || 'Cannot be blank' },
          format: (val: Dayjs) => (val ? val.format('M/DD/YYYY') : null),
          type: 'date',
        },
      }, {
        visible: animal.awo?.preSurrenderDashboard,
        subheader: 'Case number',
        content: animal.caseNumber || '-',
      }, {
        visible: !isCoSheltering,
        subheader: `${getExitDateTitle(animal.careType)}`,
        content: dashify(moment(animal.exitDate).format('M/DD/YYYY')),
        editable: {
          parentLabel: `${animal.name}_${idx}`,
          label: `${getExitDateTitle(animal.careType)}`,
          name: `animals[${idx}].exitDate`,
          validation: { validate: (val: unknown) => val !== null || 'Cannot be blank' },
          format: (val: Dayjs) => (val ? val.format('M/DD/YYYY') : null),
          type: 'date',
        },
      }, {
        visible: !isCoSheltering,
        subheader: 'Microchip',
        content: dashify(animal.microchipNumber || animal.externalId),
        action: (
          <CopyToClipboard message="Microchip copied">
            {
              (copy) => (
                <IconButton sx={{ marginRight: '-4px' }} onClick={() => copy(animal.microchipNumber || animal.externalId || '')}>
                  <ContentCopyOutlined fontSize="small" />
                </IconButton>
              )
            }
          </CopyToClipboard>
        ),
      }],
    };
    return (
      <>
        <ListItemButton
          key={animal.name}
          onClick={() => toggleExpandables(animalOption.title)}
        >
          <ListItemText
            sx={{ margin: '2px 0' }}
            primary={`${!isEmpty(animal.name) ? capitalize(animal.name) : 'No  name'} ${animalCare(animal) ? `(${animalCare(animal)})` : ''} ${animal.animalType.toLowerCase() === 'dog' ? '🐶' : '🐱'}${inactive ? '🚫' : ''}`}
          />
          {expandables.has(animalOption.title)
            ? <ExpandLess sx={{ opacity: 0.56 }} />
            : <ExpandMore sx={{ opacity: 0.56 }} />}
        </ListItemButton>
        <Collapse in={expandables.has(animalOption.title)} timeout="auto" unmountOnExit>
          <List
            component="div"
            disablePadding
          >
            {
              animalOption.items.map((item) => ((item.visible === undefined || !!item.visible)
                ? (
                  <ListItemEditable
                    primary={item.subheader}
                    secondary={item.content}
                    action={item.action}
                    editable={item.editable}
                  />
                )
                : null))
            }
            {animal.awo?.preSurrenderDashboard && animal.awo.smsName !== 'Pawlytics' && animal.animalMedias.length > 0
              ? (
                <>
                  {animal.animalMedias.map((am) => (
                    <ListItemEditable
                      primary={`Attachment ${moment(am.dateCreated).format('M/DD/YYYY')}`}
                      secondary={(
                        <Link
                          href={get(am, 'pdfMonkeyUrl.url') || am.url}
                          underline="hover"
                          rel="noopener"
                          target="_blank"
                        >
                          {am.url.includes('typeform') ? 'Intake Form' : 'Activity Report'}
                        </Link>
                      )}
                      action={(
                        <CopyToClipboard message="Thinkific express sign-in link copied">
                          {
                            (copy) => (
                              <IconButton onClick={() => copy(get(am, 'pdfMonkeyUrl.url') || am.url)}>
                                <ContentCopyOutlined fontSize="small" />
                              </IconButton>
                            )
                          }
                        </CopyToClipboard>
                      )}
                    />
                  ))}
                </>
              ) : null}
            {!animal.awo?.preSurrenderDashboard && animal.awo.smsName !== 'Pawlytics' && animal.animalMedias.length > 0
              ? (
                <ListItemEditable
                  primary="Pre-adoption photos"
                  secondary={(
                    <Box sx={{
                      paddingTop: 1, display: 'flex', flexWrap: 'wrap', gap: '4px',
                    }}
                    >
                      {animal.animalMedias.map((animalMedia, animalIndex) => (
                        <MediaThumbnail
                          setDialogDate={setDialogDate}
                          setDialogImg={setDialogImg}
                          setImgDialogOpen={setImgDialogOpen}
                          animalMedia={animalMedia}
                          animalName={animal.name}
                          index={animalIndex}
                        />
                      ))}
                    </Box>
                  )}
                />
              ) : null}
          </List>
        </Collapse>
      </>
    );
  };

  const thinkificLink = useMemo(
    () => `https://learn.petcademy.org/users/express_signin?email=${petParent.email.toLowerCase()}`,
    [petParent.email],
  );

  const hubLink = useMemo(() => (
    petParent.activeAuthToken
      ? `https://${window.location.host}/authenticate?token=${petParent.activeAuthToken.token}`
      : null
  ), [petParent.activeAuthToken]);

  let expirationDate = petParent.dateCancelled && !premiumOrder
    ? dashify(dayjs(petParent.dateCancelled).format('M/D/YYYY'))
    : dashify(dayjs().add(membershipDaysLeft, 'days').format('M/D/YYYY'));

  if (isCoSheltering) {
    expirationDate = get(petParent, 'animals[0].exitDate', null);
    if (expirationDate) {
      expirationDate = dayjs(expirationDate).add(30, 'days').format('M/D/YYYY');
    }
  }

  const options: ProfileItemOption[] = [{
    visible: isAdmin,
    title: 'Membership',
    items: [{
      visible: true,
      subheader: 'Type',
      content: dashify(membershipString),
    }, {
      visible: true,
      subheader: 'Status',
      content: dashify(membershipStatus),
    }, {
      visible: true,
      subheader: 'Start date',
      content: dashify(dayjs(petParent.dateStarted).format('M/D/YYYY')),
    }, {
      visible: true,
      subheader: 'Expiration date',
      content: dashify(expirationDate),
    }, {
      visible: true,
      subheader: 'Last login',
      content: dashify(dayjs(petParent.lastLoginDate).format('M/D/YYYY')),
    }, {
      visible: true,
      subheader: 'Thinkific express sign-in link',
      content: <Link href={thinkificLink} target="_blank" rel="noopener">{thinkificLink}</Link>,
      action: (
        <CopyToClipboard message="Thinkific express sign-in link copied">
          {
            (copy) => (
              <IconButton sx={{ marginRight: '-4px' }} onClick={() => copy(thinkificLink)}>
                <ContentCopyOutlined fontSize="small" />
              </IconButton>
            )
          }
        </CopyToClipboard>
      ),
    }, {
      visible: true,
      subheader: 'Pet Parent Hub',
      content: hubLink ? <Link href={hubLink} target="_blank" rel="noopener">{hubLink}</Link> : '-',
      action: hubLink ? (
        <CopyToClipboard message="Pet parent hub sign-in link copied">
          {
            (copy) => (
              <IconButton sx={{ marginRight: '-4px' }} onClick={() => copy(hubLink)}>
                <ContentCopyOutlined fontSize="small" />
              </IconButton>
            )
          }
        </CopyToClipboard>
      ) : null,
    }],
  }, {
    visible: true,
    title: 'Details',
    items: [{
      visible: true,
      subheader: isCoSheltering ? 'Name' : 'First name',
      content: dashify(petParent.firstName),
      editable: {
        parentLabel: 'Details',
        label: 'First name',
        name: 'firstName',
        validation: { required: 'Cannot be blank' },
      },
    }, {
      visible: !isCoSheltering,
      subheader: 'Last name',
      content: dashify(petParent.lastName),
      editable: {
        parentLabel: 'Details',
        label: 'Last name',
        name: 'lastName',
        validation: { required: 'Cannot be blank' },
      },
    }, {
      visible: true,
      subheader: 'Email',
      content: dashify(petParent.email.toLowerCase()),
      action: (
        <CopyToClipboard message="Email copied">
          {
            (copy) => (
              <IconButton sx={{ marginRight: '-4px' }} onClick={() => copy(petParent.email.toLowerCase())}>
                <ContentCopyOutlined fontSize="small" />
              </IconButton>
            )
          }
        </CopyToClipboard>
      ),
      editable: {
        parentLabel: 'Details',
        label: 'Email',
        name: 'email',
        validation: { required: 'Cannot be blank' },
      },
    }, {
      visible: true,
      subheader: 'Phone Number',
      content: dashify(phoneFormatter(petParent.phoneNumber)),
      editable: {
        parentLabel: 'Details',
        label: 'Phone Number',
        name: 'phoneNumber',
        type: 'phone',
      },
    }, {
      visible: !isCoSheltering,
      subheader: 'Location',
      content: address,
      editable: {
        parentLabel: 'Details',
        type: 'address',
      },
    }, {
      visible: !isCoSheltering,
      subheader: 'Contact preference',
      content: dashify(petParent.smsPreferred ? 'SMS' : 'Email'),
    }, {
      visible: true,
      subheader: 'SMS status',
      content: petParent.smsOptOutDate ? 'Opted-out' : 'Opted-in',
    }],
  }];

  return (
    <>
      <style>
        {
          `@keyframes animate-rotation{
            from {
              -webkit-transform: scaleX(-1) rotate(360deg);
            }
            to {
              -webkit-transform: scaleX(-1) rotate(0deg);
            }
          }`
        }
      </style>
      <Box sx={{
        px: 2,
        py: 2,
      }}
      >
        <Typography variant="h4bold">
          {capitalize(petParent.firstName)}
          {' '}
          {!isCoSheltering && capitalize(petParent.lastName)}
        </Typography>
      </Box>
      <EditingModeContext.Provider value={editingProviderDefault}>
        <List>
          {options.map((option) => option.visible && (
            <>
              <ListItemButton
                onClick={() => toggleExpandables(option.title)}
              >
                <ListItemText sx={{ margin: '2px 0' }} primary={option.title} />
                {expandables.has(option.title)
                  ? <ExpandLess sx={{ opacity: 0.56 }} />
                  : <ExpandMore sx={{ opacity: 0.56 }} />}
              </ListItemButton>
              <Collapse in={expandables.has(option.title)} timeout="auto" unmountOnExit>
                <List
                  component="div"
                  disablePadding
                >
                  {
                    option.items.map((item) => item.visible && (
                      <ListItemEditable
                        primary={item.subheader}
                        secondary={item.content}
                        action={item.action}
                        editable={item.editable}
                      />
                    ))
                  }
                  {option.title === 'Details' && petParent.awo.smsName !== 'Pawlytics' && petParent.animalMedias.length > 0 ? (
                    <ListItemEditable
                      primary="Photos and videos"
                      secondary={(
                        <Box sx={{
                          paddingTop: 1, display: 'flex', flexWrap: 'wrap', gap: '4px',
                        }}
                        >
                          {petParent.animalMedias.map((am, ai) => (
                            <MediaThumbnail
                              setDialogDate={setDialogDate}
                              setDialogImg={setDialogImg}
                              setImgDialogOpen={setImgDialogOpen}
                              animalMedia={am}
                              animalName=""
                              index={ai}
                            />
                          ))}
                        </Box>
                      )}
                    />
                  ) : null}
                </List>
              </Collapse>
            </>
          ))}
          {groupedAnimals.active.map((a, i) => animalContent(a, i))}
          {
            showInactiveAnimals && groupedAnimals.inactive.length > 0 && (
              <Box
                sx={{
                  borderTop: 1,
                  borderBottom: 1,
                  borderColor: 'rgba(0,0,0,0.12)',
                }}
              >
                <ListSubheader sx={{ top: '70px' }}>Inactive animals</ListSubheader>
                {groupedAnimals.inactive.map((a, i) => animalContent(a, i, true))}
              </Box>
            )
          }
          {isAdmin ? (
            <>
              <ListItemButton
                key="smsMessages"
                onClick={() => toggleExpandables('SMS Messages')}
              >
                <ListItemText primary="SMS Messages" sx={{ margin: '2px 0' }} />
                {expandables.has('SMS Messages') ? <ExpandLess sx={{ opacity: 0.56 }} /> : <ExpandMore sx={{ opacity: 0.56 }} />}
              </ListItemButton>
              <Collapse
                in={expandables.has('SMS Messages')}
                timeout="auto"
                unmountOnExit
              >
                <List
                  sx={{
                    px: 2,
                  }}
                >
                  <MessageList
                    petParentId={petParent.id}
                    petParentName={petParent.firstName}
                    timezone={awo.timezone}
                  />
                </List>
              </Collapse>
            </>
          ) : null}
          <>
            <ListItemButton
              key="updates"
              onClick={() => toggleExpandables('Updates')}
            >
              <ListItemText primary="Updates" sx={{ margin: '2px 0' }} />
              {expandables.has('Updates') ? <ExpandLess sx={{ opacity: 0.56 }} /> : <ExpandMore sx={{ opacity: 0.56 }} />}
            </ListItemButton>
            <Collapse
              in={expandables.has('Updates')}
              timeout="auto"
              unmountOnExit
            >
              <Box
                sx={{
                  px: 2,
                }}
              >
                <TypeformResponse
                  petParent={petParent}
                  setDialogDate={setDialogDate}
                  setDialogImg={setDialogImg}
                  setImgDialogOpen={setImgDialogOpen}
                />
              </Box>
            </Collapse>
          </>
        </List>
      </EditingModeContext.Provider>
      <MediaDialog
        dialogDate={dialogDate}
        dialogImg={dialogImg}
        imgDialogOpen={imgDialogOpen}
        setImgDialogOpen={setImgDialogOpen}
      />
    </>
  );
}
