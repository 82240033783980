import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  Snackbar,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
  useForm,
} from 'react-hook-form-mui';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { CREATE_MESSAGE_TEMPLATE, DELETE_MESSAGE_TEMPLATE, UPDATE_MESSAGE_TEMPLATE } from '../../queries';
import vMessageTemplatesVar from './VMessagesCache';
import { MessageTemplateType } from '../../types/MessageTemplate';

const messageTemplateSchema = {
  text: yup.string().required('Message cannot be blank'),
  sendDelay: yup.number().min(0, 'Value must be 0 - 365').max(365, 'Value must be 0 - 365').typeError('Value must be 0 - 365')
    .required('Value must be 0 - 365'),
  position: yup.number().required('Position is required'),
  note: yup.string(),
  numericalResponse: yup.boolean(),
  stateIdx: yup.number(),
};

interface MessageTemplateInputType {
  id: string;
  campaignId: string;
  text: string;
  sendDelay: number;
  position: number;
  note: string;
  numericalResponse: string;
}

function EditableMessageTemplate(
  {
    messageTemplate,
    deleteHandler,
    setEditedText,
    setHasMadeEdit,
  }: {
    messageTemplate: MessageTemplateType,
    deleteHandler: () => void,
    setEditedText: (value: string) => void,
    setHasMadeEdit: (value: boolean) => void,
  },
) {
  const [characterCount, setCharacterCount] = React.useState(messageTemplate.text.length);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const mutation = parseInt(messageTemplate.id, 10) >= 0 ? UPDATE_MESSAGE_TEMPLATE : CREATE_MESSAGE_TEMPLATE;
  const [upsertMessageTemplate, { loading: upsertLoading }] = useMutation(mutation, {
    onCompleted: (d) => {
      const { response } = d;
      setSnackbarMessage(response.message);
      setSnackbarOpen(true);
      setHasMadeEdit(false);
      vMessageTemplatesVar(undefined);
    },
    refetchQueries: [
      'GetCampaigns',
    ],
  });
  const [deleteMessageTemplate, { loading: deleteLoading }] = useMutation(DELETE_MESSAGE_TEMPLATE, {
    onCompleted: (d) => {
      const { response } = d;
      setSnackbarMessage(response.message);
      setSnackbarOpen(true);
    },
    refetchQueries: [
      'GetCampaigns',
    ],
});
  const formSubmitHandler = async (values: Required<MessageTemplateInputType>) => {
    const variables = {
      id: messageTemplate?.id ? messageTemplate.id : undefined,
      messageTemplate: {
        ...values,
        campaignId: messageTemplate.campaignId,
      },
    };
    await upsertMessageTemplate({ variables });
  };

  const formContext = useForm({
    defaultValues: {
      text: messageTemplate.text,
      sendDelay: messageTemplate.sendDelay,
      position: messageTemplate.position,
      note: messageTemplate.note || '',
      numericalResponse: !!messageTemplate.numericalResponse,
    },
    resolver: yupResolver(yup.object(messageTemplateSchema)),
  });

  React.useEffect(() => {
    formContext.reset({
      text: messageTemplate.text,
      sendDelay: messageTemplate.sendDelay,
      position: messageTemplate.position,
      note: messageTemplate.note || '',
      numericalResponse: !!messageTemplate.numericalResponse,
    });
    setCharacterCount(messageTemplate.text.length);
    setEditedText(messageTemplate.text);
  }, [messageTemplate, setEditedText, formContext]);

  const handleDeleteMessage = async () => {
    setDialogOpen(false);
    if (messageTemplate.id === '-1') {
      deleteHandler();
      setSnackbarMessage('Message deleted');
      setSnackbarOpen(true);
      vMessageTemplatesVar(undefined);
      return;
    }
    await deleteMessageTemplate({ variables: { id: messageTemplate.id } });
  };

  return (
    <FormContainer
      formContext={formContext}
      onSuccess={formSubmitHandler as any}
      onError={(errors) => console.log(errors)}
    >
      <Grid sx={{ mb: 5 }} container rowGap={3}>
        <Grid container rowGap={1}>
          <Box>
            <Typography
              variant="overline"
            >
              Message
            </Typography>
          </Box>
          <TextFieldElement
            label="Copywriting"
            name="text"
            multiline
            rows={5}
            fullWidth
            variant="filled"
            helperText={`${characterCount} characters. Segments are every 160 characters (160, 320, 480)`}
            onChange={(e) => {
              setHasMadeEdit(true);
              setEditedText(e.target.value);
              setCharacterCount(e.target.value.length);
            }}
          />
        </Grid>
        <Accordion sx={{ width: '100%' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>Variables</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <Typography variant="overline">GENERIC</Typography>
              <Box>
                <Typography display="inline" variant="body1" fontWeight="bold">{'{f_name}'}</Typography>
                <Typography display="inline" variant="body1">{" Pet parent's first name"}</Typography>
              </Box>
              <Box>
                <Typography display="inline" variant="body1" fontWeight="bold">{'{a_name}'}</Typography>
                <Typography display="inline" variant="body1">{' Animal name'}</Typography>
              </Box>
              <Box>
                <Typography display="inline" variant="body1" fontWeight="bold">{'{care_type}'}</Typography>
                <Typography display="inline" variant="body1">{' Animal care type'}</Typography>
              </Box>
              <Box>
                <Typography display="inline" variant="body1" fontWeight="bold">{'{email}'}</Typography>
                <Typography display="inline" variant="body1">{" Pet parent's email"}</Typography>
              </Box>
              <Box>
                <Typography display="inline" variant="body1" fontWeight="bold">{'{sign_in_link}'}</Typography>
                <Typography display="inline" variant="body1">{" Pet parent's thinkific sign in link"}</Typography>
              </Box>
              <Box>
                <Typography display="inline" variant="body1" fontWeight="bold">{'{hub_sign_in_link}'}</Typography>
                <Typography display="inline" variant="body1">{" Pet parent's hub sign in link"}</Typography>
              </Box>
              <Box>
                <Typography display="inline" variant="body1" fontWeight="bold">{'{awo_name}'}</Typography>
                <Typography display="inline" variant="body1">{' Organization name'}</Typography>
              </Box>
              <Box>
                <Typography display="inline" variant="body1" fontWeight="bold">{'{awo_number}'}</Typography>
                <Typography display="inline" variant="body1">{" Organization's SMS number"}</Typography>
              </Box>
              <Box>
                <Typography display="inline" variant="body1" fontWeight="bold">{'{end_date}'}</Typography>
                <Typography display="inline" variant="body1">{' Last day of active membership for pet parent'}</Typography>
              </Box>
              <Box>
                <Typography display="inline" variant="body1" fontWeight="bold">{'{hub_welcome}'}</Typography>
                <Typography display="inline" variant="body1">{' The Hub welcome page'}</Typography>
              </Box>
              <Box>
                <Typography display="inline" variant="body1" fontWeight="bold">{'{hub_home}'}</Typography>
                <Typography display="inline" variant="body1">{' The Hub home page'}</Typography>
              </Box>
              <Box>
                <Typography display="inline" variant="body1" fontWeight="bold">{'{hub_my_pets}'}</Typography>
                <Typography display="inline" variant="body1">{' The Hub my pets page'}</Typography>
              </Box>
              <Box>
                <Typography display="inline" variant="body1" fontWeight="bold">{'{hub_ask}'}</Typography>
                <Typography display="inline" variant="body1">{' The Hub ask page'}</Typography>
              </Box>
              <Box>
                <Typography display="inline" variant="body1" fontWeight="bold">{'{hub_dog_day_10_survey}'}</Typography>
                <Typography display="inline" variant="body1">{' The Hub dog adopter 10 day survey'}</Typography>
              </Box>
              <Box>
                <Typography display="inline" variant="body1" fontWeight="bold">{'{hub_cat_day_10_survey}'}</Typography>
                <Typography display="inline" variant="body1">{' The Hub cat adopter 10 day survey'}</Typography>
              </Box>
              <Box>
                <Typography display="inline" variant="body1" fontWeight="bold">{'{hub_adoption_feedback_survey}'}</Typography>
                <Typography display="inline" variant="body1">{' The Hub adoption feedback survey'}</Typography>
              </Box>
              <Box>
                <Typography display="inline" variant="body1" fontWeight="bold">{'{hub_petcademy_experience_survey}'}</Typography>
                <Typography display="inline" variant="body1">{' The Hub petcademy experience survey'}</Typography>
              </Box>
              <Box>
                <Typography display="inline" variant="body1" fontWeight="bold">{'{awo_donation}'}</Typography>
                <Typography display="inline" variant="body1">{' The AWO donation link'}</Typography>
              </Box>
            </Box>
            <Box>
              <Typography variant="overline">Typeforms</Typography>
              <Box>
                <Typography display="inline" variant="body1" fontWeight="bold">Append to typeform links:</Typography>
                <Typography display="inline" variant="body1">{' #name={a_name}&onboarded={onboard_date}&email={email}&awo={awo_name_encoded}&care_type={care_type}'}</Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid container rowGap={3}>
        <Grid container rowGap={1}>
          <Box>
            <Typography
              variant="overline"
            >
              Customization
            </Typography>
          </Box>
          <TextFieldElement
            label="Note (Optional)"
            multiline
            variant="standard"
            rows={2}
            fullWidth
            helperText="For internal reference only"
            name="note"
            onChange={() => setHasMadeEdit(true)}
          />
        </Grid>
        <TextFieldElement
          variant="standard"
          label="Send on day"
          fullWidth
          helperText="Value must be 0 - 365"
          name="sendDelay"
          onChange={() => setHasMadeEdit(true)}
        />
        <FormControl variant="standard" fullWidth>
          <SelectElement
            name="numericalResponse"
            hiddenLabel
            label="1-5 Response"
            options={[
              {
                id: 'false',
                label: 'False',
              },
              {
                id: 'true',
                label: 'True',
              },
            ]}
            onChange={() => setHasMadeEdit(true)}
          />
          <FormHelperText>Indicate whether the response to this question must be a value of 1-5.</FormHelperText>
        </FormControl>
      </Grid>
      <Grid
        sx={{
        borderTop: 1,
        borderColor: 'primary.50',
        paddingTop: 3,
      }}
        container
        rowGap={3}
      >
        <Box sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        >
          <Button type="submit" disabled={upsertLoading} variant="contained">Save changes</Button>
          <Button
            color="error"
            disabled={deleteLoading}
            onClick={() => setDialogOpen(true)}
          >
            Delete message
          </Button>
        </Box>
      </Grid>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="message-deletion"
        aria-describedby="message-delete-description"
        maxWidth={false}
        PaperProps={{
          sx: {
            width: '444px',
            maxWidth: '90vw',
          },
        }}
      >
        <DialogTitle variant="h6">Are you sure you want to delete this message?</DialogTitle>
        <DialogContent>
          <Typography>This action cannot be undone</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            onClick={handleDeleteMessage}
          >
            Delete Message
          </Button>
          <Button
            onClick={() => setDialogOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        message={snackbarMessage}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      />
    </FormContainer>
  );
}

export default EditableMessageTemplate;
